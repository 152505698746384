import { useEffect } from 'react';

import { getConfig } from '@townsquare/config';
import { createKudosEmbedRoute } from '@townsquare/embeds';
import { redirect } from '@townsquare/facade';
import { goalViewRoute } from '@townsquare/goal-view/route';
import { projectRoute } from '@townsquare/project-view/route';
import { profileRoute, teamProfileRoute } from '@townsquare/ptc-directory-view';
import { generatePath, useGeneratePath, useRouteDependencies } from '@townsquare/router/primitives';
import { topicRoute } from '@townsquare/topic-view/route';
import { useWorkspaceCloudId, useWorkspaceFeatureContext, useWorkspaceStore } from '@townsquare/workspace-store';
import { WorkspaceType } from '@townsquare/workspace-store/types';

export const createAbsoluteUrl = (path = '') => {
  return getConfig().fullUrl + path;
};

export const getAbsoluteUrl = (type: WorkspaceType, path: string) => {
  const config = getConfig();
  const fullUrl = type === 'GLOBAL_EXPERIENCE' ? config.homeUrl : config.classicUrl;
  return fullUrl + path;
};

export const getProjectShareLink = (
  routeDependencies: ReturnType<typeof useRouteDependencies>,
  projectKey: string,
  tabName?: 'about' | 'updates' | 'learnings',
) => {
  const path = generatePath(projectRoute, { baseParams: { id: projectKey, tabName }, ...routeDependencies });
  return createAbsoluteUrl(path);
};

export const useProjectShareLink = (projectKey: string, tab?: 'about' | 'update') => {
  const path = useGeneratePath(projectRoute, { id: projectKey, tabName: tab });
  return createAbsoluteUrl(path);
};

export const getProjectUpdateShareLink = (
  routeDependencies: ReturnType<typeof useRouteDependencies>,
  projectKey: string,
  updateId: string,
) => {
  const path = generatePath(projectRoute, {
    baseParams: { id: projectKey, tabName: 'updates', resourceId: updateId },
    ...routeDependencies,
  });
  return createAbsoluteUrl(path);
};

export const getGoalShareLink = (
  routeDependencies: ReturnType<typeof useRouteDependencies>,
  goalKey: string,
  tabName?: 'about' | 'updates',
) => {
  const path = generatePath(goalViewRoute, { baseParams: { id: goalKey, tabName }, ...routeDependencies });
  return createAbsoluteUrl(path);
};

export const getGoalUpdateShareLink = (
  routeDependencies: ReturnType<typeof useRouteDependencies>,
  goalKey: string,
  updateId: string,
) => {
  const path = generatePath(goalViewRoute, {
    baseParams: { id: goalKey, tabName: 'updates', resourceId: updateId },
    ...routeDependencies,
  });
  return createAbsoluteUrl(path);
};

export const getTopicShareLink = (
  routeDependencies: ReturnType<typeof useRouteDependencies>,
  topicUuid?: string | null,
) => {
  const path = generatePath(topicRoute, { baseParams: { uuid: topicUuid }, ...routeDependencies });
  return createAbsoluteUrl(path);
};

export const useTopicShareLink = (topicUuid?: string | null) => {
  const path = useGeneratePath(topicRoute, { uuid: topicUuid });
  return createAbsoluteUrl(path);
};

export const getTeamShareLink = (routeDependencies: ReturnType<typeof useRouteDependencies>, teamId: string) => {
  const path = generatePath(teamProfileRoute, {
    baseParams: { id: teamId },
    ...routeDependencies,
  });
  return createAbsoluteUrl(path);
};

export const getPeopleProfileLink = (routeDependencies: ReturnType<typeof useRouteDependencies>, aaid: string) => {
  const path = generatePath(profileRoute, {
    baseParams: { id: aaid },
    ...routeDependencies,
  });
  return createAbsoluteUrl(path);
};

export const useCreateKudosLink = () => {
  const path = useGeneratePath(createKudosEmbedRoute);
  return createAbsoluteUrl(path);
};

const { adminHubUrl, adminBillingPath, adminHubSitePath, billingPreviewPath } = getConfig();
export const useManageBillingLink = (): string => {
  const { entitlementId, transactionAccountId } = useWorkspaceFeatureContext()[0] || {};
  let url = adminHubUrl + adminBillingPath;
  if (transactionAccountId) {
    url += `/${transactionAccountId}`;
    if (entitlementId) {
      url += `/entitlement/${entitlementId}`;
    }
  }
  return url;
};

export const useAdminHubLinkForSite = (): string => {
  const [workspaceCloudId] = useWorkspaceCloudId();
  let url = adminHubUrl;
  if (workspaceCloudId) {
    url += `${adminHubSitePath}/${workspaceCloudId}`;
  }
  return url;
};

export const useBillingPreviewUrl = (): string => {
  const [{ organisationId }] = useWorkspaceStore();
  return `${adminHubUrl}/o/${organisationId}${billingPreviewPath}`;
};

export const ManageBillingRedirect = () => {
  const billingUrl = useManageBillingLink();
  useEffect(() => {
    if (billingUrl) {
      redirect(billingUrl);
    }
  }, [billingUrl]);
  return null;
};

import { SpotlightManager } from '@atlaskit/onboarding';
import { Content, PageLayout, Main, TopNavigation, RightSidebar } from '@atlaskit/page-layout';
import { layers } from '@atlaskit/theme';
import React, { useEffect } from 'react';
import { RouteComponent } from 'react-resource-router';
import styled from 'styled-components';

import { AkAnalyticsListeners } from '@townsquare/analytics/ak-listener';
import { CrossFlowWAC, CrossFlowProvider } from '@townsquare/crossflow';
import { Drawers } from '@townsquare/drawers';
import { Flags } from '@townsquare/flags';
import { TownsquareIntlProvider } from '@townsquare/i18n';
import { Intercom } from '@townsquare/intercom';
import { useKeyboardShortcutActions } from '@townsquare/keyboard-shortcuts';
import { Modals } from '@townsquare/modals/ui';
import { Navigation, useRightSidebarState } from '@townsquare/navigation';
import { RovoChatSidebar } from '@townsquare/navigation/rovo-chat';
import { PageTitle } from '@townsquare/page-title';
import { RouteListener } from '@townsquare/performance';
import PostOfficeContextProvider from '@townsquare/post-office';
import { SiteBannerProvider } from '@townsquare/site-banner';
import { useNoWorkspaceExperience } from '@townsquare/workspace-store';

import { WebVitals } from '../WebVitals';

import { WebApplicationProps } from './types';

export const WebApplication = ({ analyticsClient, isHelpOpen }: WebApplicationProps) => {
  const keyboardActions = useKeyboardShortcutActions();
  const noWorkspaceExperience = useNoWorkspaceExperience();

  const { currentPanel, width } = useRightSidebarState();

  useEffect(() => {
    if (!noWorkspaceExperience) {
      void keyboardActions.initStore();
    }
  }, [keyboardActions, noWorkspaceExperience]);

  return (
    <TownsquareIntlProvider>
      <PostOfficeContextProvider>
        <CrossFlowProvider>
          <AkAnalyticsListeners client={analyticsClient}>
            <SpotlightManager>
              <SiteBannerProvider>
                <PageLayout testId="townsquare-web">
                  <RouteListener />
                  <WebVitals />
                  <TopNavigation isFixed>
                    <Navigation />
                  </TopNavigation>
                  <Content>
                    {isHelpOpen && (
                      <>
                        {/* Add anchor for help-panel to attach to */}
                        <HelpSidePanel id="help-panel" />
                      </>
                    )}
                    <PageTitle />
                    <div>
                      <Flags />
                      <Modals />
                      <Drawers />
                    </div>
                    <Main>
                      <RouteComponent />
                    </Main>
                    <Intercom />
                    {currentPanel === 'rovo-chat' && (
                      <RightSidebar width={width}>
                        <RovoChatSidebar />
                      </RightSidebar>
                    )}
                  </Content>
                </PageLayout>
              </SiteBannerProvider>
            </SpotlightManager>
          </AkAnalyticsListeners>
        </CrossFlowProvider>
      </PostOfficeContextProvider>
      {/* WAC via Start Cross Flow touch point support */}
      <CrossFlowWAC />
    </TownsquareIntlProvider>
  );
};

const HelpSidePanel = styled.div({
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
  zIndex: layers.navigation() - 1,
  position: 'absolute',
  right: 0,
});

import Button from '@atlaskit/button';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import React, { PropsWithChildren, useEffect, useState } from 'react';

import { RemoveIcon } from './icons/Remove';
import { FilterTagText, FilterTagWrapper } from './style';

interface Props {
  removeButtonLabel: string;
  onRemove: () => void;
  appearance: 'default' | 'danger';
  labelForTestId?: string;
}

export const FilterTag = (props: PropsWithChildren<Props>) => {
  const [appearance, setAppearance] = useState<Props['appearance']>(props.appearance);

  useEffect(() => {
    setAppearance(props.appearance);
  }, [props.appearance]);

  return (
    <FilterTagWrapper data-testid="metafilter-filter-tag" appearance={appearance}>
      <Tooltip content={props.children}>
        <FilterTagText appearance={appearance}>{props.children}</FilterTagText>
      </Tooltip>
      <Button
        iconBefore={
          <RemoveIcon
            size="medium"
            label={props.removeButtonLabel}
            primaryColor={appearance === 'danger' ? token('color.icon.danger') : token('color.icon.subtle')}
          />
        }
        onClick={props.onRemove}
        appearance="subtle-link"
        spacing="none"
        onMouseEnter={() => setAppearance('danger')}
        onMouseLeave={() => setAppearance(props.appearance ?? 'default')}
        style={{
          // eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
          height: '1rem',
        }}
        testId={'metafilter-remove-filter-tag-' + props.labelForTestId?.replace(' ', '_').toLowerCase()}
      />
    </FilterTagWrapper>
  );
};

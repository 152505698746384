import { useCallback, useMemo } from 'react';
import { useQueryParam } from 'react-resource-router';
import { validate as uuidValidate } from 'uuid';

import { DirectoryRoute } from '@townsquare/directory-header/types';
import { useDirectoryViewColumns } from '@townsquare/directory-view-store';
import { isGateEnabled } from '@townsquare/stat-sig/gate';

import { ColumnInfo } from '../types';
import { directoryViewColumnToUrlColumn } from '../utils/converter';

import { useCustomColumns } from './useCustomColumns';

const ALL_PROJECT_COLUMNS: ColumnInfo[] = [
  { text: 'Project', id: 'name', locked: true },
  { text: 'Status', id: 'status' },
  { text: 'Start date', id: 'startDate' },
  { text: 'Target date', id: 'targetDate' },
  { text: 'Owner', id: 'owner' },
  { text: 'Following', id: 'following' },
  { text: 'Last updated', id: 'lastUpdated' },
  { text: 'Goals', id: 'goals' },
  { text: 'Tags', id: 'tags' },
  { text: 'Team', id: 'team' },
  { text: 'Contributors', id: 'contributors' },
  { text: 'Related projects', id: 'relatedProjects' },
  { text: 'Follower count', id: 'followerCount' },
];
const DEFAULT_COLUMN_IDS = ['name', 'status', 'progress', 'targetDate', 'owner', 'following', 'lastUpdated'];
const DEFAULT_PROJECT_COLUMNS = ALL_PROJECT_COLUMNS.filter(col => DEFAULT_COLUMN_IDS.includes(col.id));

const ALL_GOAL_COLUMNS: ColumnInfo[] = [
  { text: 'Goal', id: 'name', locked: true },
  { text: 'Status', id: 'status' },
  { text: 'Target date', id: 'targetDate' },
  { text: 'Owner', id: 'owner' },
  { text: 'Following', id: 'following' },
  { text: 'Last updated', id: 'lastUpdated' },
  { text: 'Tags', id: 'tags' },
  { text: 'Team', id: 'team' },
  // This needs an id of 'relatedProjects' to match the project column despite the different text
  { text: 'Contributing projects', id: 'relatedProjects' },
  { text: 'Follower count', id: 'followerCount' },
];

export const getAllColumns = (entityType: DirectoryRoute): ColumnInfo[] => {
  switch (entityType) {
    case 'projects':
      return ALL_PROJECT_COLUMNS;
    case 'goals':
      if (isGateEnabled('metrics-goals-m1')) {
        // Add progress after Status
        const statusIndex = ALL_GOAL_COLUMNS.findIndex(col => col.id === 'status');
        return [
          ...ALL_GOAL_COLUMNS.slice(0, statusIndex + 1),
          { text: 'Progress', id: 'progress' },
          ...ALL_GOAL_COLUMNS.slice(statusIndex + 1),
        ];
      }

      return ALL_GOAL_COLUMNS;
  }
};

const DEFAULT_GOAL_COLUMNS = getAllColumns('goals').filter(col => DEFAULT_COLUMN_IDS.includes(col.id));

const getDefaultColumns = (entityType: DirectoryRoute): ColumnInfo[] => {
  switch (entityType) {
    case 'projects':
      return DEFAULT_PROJECT_COLUMNS;
    case 'goals':
      // If the goals metrics gate is enabled, reload the default columns so that the FF is correctly evaluated.
      return isGateEnabled('metrics-goals-m1')
        ? getAllColumns('goals').filter(col => DEFAULT_COLUMN_IDS.includes(col.id))
        : DEFAULT_GOAL_COLUMNS;
  }
};

const getMinimumColumns = (entityType: DirectoryRoute) => getAllColumns(entityType).filter(c => c.locked);

const moveColumnToEnd = (columns: ColumnInfo[], columnId: string) => {
  const columnIndex = columns.findIndex(col => col.id === columnId);
  if (columnIndex !== -1) {
    const lastUpdatedColumn = columns[columnIndex];
    return columns.filter(col => col.id !== columnId).concat(lastUpdatedColumn);
  }
  return columns;
};

const correctColumnsOrdering = (columns: ColumnInfo[]): ColumnInfo[] => {
  return moveColumnToEnd(moveColumnToEnd(columns, 'following'), 'lastUpdated');
};

export const realColumnsFromIds = (entityType: DirectoryRoute, ids: string[], customs: ColumnInfo[] = []) => {
  if (!ids || !ids.length) {
    return getMinimumColumns(entityType);
  }

  return getAllColumns(entityType)
    .concat(customs)
    .filter(column => column.locked || ids.includes(column.id));
};

export const toUrlParam = (entityType: DirectoryRoute, columnIds: string[]) => {
  const lockedColumnIds = new Set(getMinimumColumns(entityType).map(c => c.id));
  return encodeURIComponent(columnIds.filter(c => !lockedColumnIds.has(c)).join(','));
};

export const fromUrlParam = (entityType: DirectoryRoute, urlParam?: string): string[] => {
  // On undefined input, return all columns
  if (urlParam === undefined) {
    return getDefaultColumns(entityType).map(c => c.id);
  }

  // If empty string, then the query param exists, so all columns have been deselected
  if (urlParam === '') {
    return getMinimumColumns(entityType).map(c => c.id);
  }

  return decodeURIComponent(urlParam || '')
    .split(',')
    .filter(s => !!s);
};

export function getCustomColumns(queryParam?: string): string[] {
  return decodeURIComponent(queryParam || '')
    .split(',')
    .filter(s => uuidValidate(s));
}

export function getStandardColumns(entityType: DirectoryRoute, queryParam?: string): ColumnInfo[] {
  return realColumnsFromIds(entityType, fromUrlParam(entityType, queryParam));
}

export interface ExposedColumnActions {
  addColumn(id: string): void;
  removeColumn(id: string): void;
}

export const useShownColumns = (entityType: DirectoryRoute): [ColumnInfo[], ExposedColumnActions] => {
  const [columnKeysUrlParam, setColumnKeysUrlParam] = useQueryParam('columns');
  const [directoryViewColumns] = useDirectoryViewColumns();
  const [{ columns: customColumns }] = useCustomColumns();

  const directoryViewColumnsAsColumnKeys = useMemo(
    () => directoryViewColumns?.map(directoryViewColumnToUrlColumn),
    [directoryViewColumns],
  );

  const defaultUrlParam = useMemo(
    () => toUrlParam(entityType, directoryViewColumnsAsColumnKeys ?? getDefaultColumns(entityType).map(c => c.id)),
    [directoryViewColumnsAsColumnKeys, entityType],
  );

  const [columns, columnIds] = useMemo<[ColumnInfo[], string[]]>(() => {
    let columnIds = [];
    if (columnKeysUrlParam === undefined && directoryViewColumnsAsColumnKeys) {
      columnIds = directoryViewColumnsAsColumnKeys;
    } else {
      columnIds = fromUrlParam(entityType, columnKeysUrlParam);
    }

    return [correctColumnsOrdering(realColumnsFromIds(entityType, columnIds, customColumns)), columnIds];
  }, [columnKeysUrlParam, customColumns, directoryViewColumnsAsColumnKeys, entityType]);

  const updateQueryParam = useCallback(
    (columns: ColumnInfo[]) => {
      const newKeys = toUrlParam(
        entityType,
        columns.map(c => c.id),
      );

      if (newKeys === columnKeysUrlParam) {
        return;
      }

      if (newKeys === defaultUrlParam) {
        setColumnKeysUrlParam(undefined);
        return;
      }

      setColumnKeysUrlParam(newKeys);
    },
    [columnKeysUrlParam, defaultUrlParam, entityType, setColumnKeysUrlParam],
  );

  const addColumn = useCallback(
    (newColumnId: string) => {
      if (columnIds.includes(newColumnId)) {
        return;
      }

      const newColumns = realColumnsFromIds(entityType, columnIds.concat(newColumnId), customColumns);
      updateQueryParam(newColumns);
    },
    [columnIds, customColumns, entityType, updateQueryParam],
  );

  const removeColumn = useCallback(
    (removeId: string) => {
      if (!columnIds.includes(removeId)) {
        return;
      }

      const newColumns = realColumnsFromIds(
        entityType,
        columnIds.filter(c => c !== removeId),
        customColumns,
      );
      updateQueryParam(newColumns);
    },
    [columnIds, customColumns, entityType, updateQueryParam],
  );

  return [columns, { addColumn, removeColumn }];
};

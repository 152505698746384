import AkHeading from '@atlaskit/heading';
import ArrowRightIcon from '@atlaskit/icon/core/migration/arrow-right';
import { Box, Inline, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import React, { ReactNode } from 'react';
import { usePathParam } from 'react-resource-router';

import { useAnalytics } from '@townsquare/analytics';
import { useQueryParams } from '@townsquare/router';
import { useMetaClickHandler } from '@townsquare/ui-interactions';
import { createSearchParamsFromScratch } from '@townsquare/url-utils/search-params';

import { staffDirectoryRoute } from '../route';

import { ScreenType } from './ScreenType';
import { ArrowRightIconBackground, BrowseMoreLink, BrowseMoreText } from './styles';

interface HeadingProps {
  children: ReactNode;
  browseMoreText?: string;
  screenType: ScreenType;
}

export const Heading = ({ children, screenType, browseMoreText }: HeadingProps) => {
  const analytics = useAnalytics();

  const query = useQueryParams();
  const [search] = usePathParam('search');

  const screenTypeString = ScreenType[screenType];

  const queryParams = createSearchParamsFromScratch({ ...query, screen: screenTypeString });
  const { handler } = useMetaClickHandler(
    staffDirectoryRoute,
    { params: { search }, query: Object.fromEntries(queryParams) },
    () => {
      analytics.ui('staffDirectoryBrowseAllScreenHeading', 'clicked', { screenType: screenTypeString });
    },
  );

  return (
    <Box xcss={headingContainerStyles}>
      <AkHeading size="small">
        <Inline space="space.100" as="span" alignBlock="center">
          {children}
          {browseMoreText && (
            <BrowseMoreLink onClick={handler}>
              <BrowseMoreText>{browseMoreText}</BrowseMoreText>
              <ArrowRightIconBackground>
                <ArrowRightIcon spacing="spacious" label={browseMoreText} color={token('color.icon.subtle')} />
              </ArrowRightIconBackground>
            </BrowseMoreLink>
          )}
        </Inline>
      </AkHeading>
    </Box>
  );
};

// Ideally this doesn't exist and spacing is managed higher up in a layout component
const headingContainerStyles = xcss({
  marginBlockEnd: 'space.250',
});

import Loadable from 'react-loadable';

import {
  KUDOS_PAGE,
  PEOPLE_DIRECTORY_PATH,
  PEOPLE_KUDOS_PATH_PATTERN,
  PEOPLE_TEAM_PATH_PATTERN,
  PEOPLE_AGENT_PATH_PATTERN,
  PROFILE_PAGE,
  TEAM_PROFILE_PAGE,
  AGENT_PROFILE_PAGE,
} from '@townsquare/config/routes';
import { createGlobalRoute } from '@townsquare/router/create-route';

const DirectoryViewLoadable = Loadable({
  loader: () => import(/* webpackChunkName: "DirectoryPage" */ './components/DirectoryView'),
  loading: () => null,
});

export const profileRoute = createGlobalRoute({
  component: DirectoryViewLoadable,
  exact: true,
  name: PROFILE_PAGE,
  path: `${PEOPLE_DIRECTORY_PATH}/:id/(work)?`,
  subproduct: 'townsquare-people-team-directory',
  layout: 'expanded',
});

export const teamProfileRoute = createGlobalRoute({
  component: DirectoryViewLoadable,
  exact: true,
  name: TEAM_PROFILE_PAGE,
  path: PEOPLE_TEAM_PATH_PATTERN,
  subproduct: 'townsquare-people-team-directory',
  layout: 'expanded',
});

export const agentProfileRoute = createGlobalRoute({
  component: DirectoryViewLoadable,
  exact: true,
  name: AGENT_PROFILE_PAGE,
  path: PEOPLE_AGENT_PATH_PATTERN,
  subproduct: 'townsquare-people-team-directory',
  layout: 'expanded',
});

export const kudosProfileRoute = createGlobalRoute({
  component: DirectoryViewLoadable,
  exact: true,
  name: KUDOS_PAGE,
  path: PEOPLE_KUDOS_PATH_PATTERN,
  subproduct: 'townsquare-kudos',
  layout: 'full-screen',
});

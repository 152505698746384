import { getRovoParams } from '@atlassian/conversation-assistant-pubsub';
import { createResource } from 'react-resource-router';

import { HomeResourceContext } from '@townsquare/router';

import { EntitlementAPIResponse, RovoEntitlement } from './types';

const ROVO_PRODUCT_HEADER = 'rovo';
const ROVO_EXPERIENCE_ID_HEADER = 'ai-mate';

export const rovoEntitlementResource = createResource<RovoEntitlement>({
  getData: async (_, resourceContext) => {
    const context = resourceContext as HomeResourceContext;
    const rovoParams = getRovoParams();
    // Workspace doesn't always exist on Atlassian Home **TODAY**, if we are rendering chat without a workspace, we need to default to the first cloudId (as they may still have sites)
    const cloudId = rovoParams.cloudId ?? context.collabContext?.rovoWorkspace;

    const response = await fetch('/gateway/api/assist/api/v2/configuration/entitlement', {
      headers: {
        'X-Cloudid': cloudId ?? '',
        'X-Product': ROVO_PRODUCT_HEADER,
        'X-Experience-Id': ROVO_EXPERIENCE_ID_HEADER,
      },
    });

    if (!response.ok) {
      return {
        isRovoEnabled: false,
        reason: 'Failed to fetch entitlement data',
      };
    }

    const data: EntitlementAPIResponse = await response.json();

    return {
      isRovoEnabled: data.enabled,
      reason: data.reason,
    };
  },
  getKey: () => 'rovo-entitlement-resource-key',
  type: 'ROVO_ENTITLEMENT',
  maxAge: 60_000 * 30, // 30 minutes cache during the SPA session
});

// open flag for allowing a user to override their locale
export const ATLAS_LOCALE_OVERRIDE = 'atlas.override-locale';

// variations/types for FF used in multiple packages
export const EPIC_IMPORT_PICKER = {
  VARIATION_A: 'variation-a',
  CONTROL_NOT_ENROLLED: 'control-not-enrolled',
};
export const EXPERIMENT_COHORTS = {
  VARIATION: 'variation',
  CONTROL: 'control',
  UNENROLLED: 'unenrolled',
};

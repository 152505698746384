/**
 * @generated SignedSource<<e8c02f606bd01786bbf7013bad61a045>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type GoalScoringMode = "OKR" | "SIMPLE";
export type WorkspaceType = "GLOBAL_EXPERIENCE" | "PRODUCT";
import type { FragmentRefs } from "relay-runtime";
export type storeHomeWorkspace$data = {
  readonly activationId: string | null;
  readonly aiConfig: {
    readonly enabled: boolean;
  } | null;
  readonly cloudId: string;
  readonly cloudName: string | null;
  readonly goalScoringMode: GoalScoringMode;
  readonly id: string;
  readonly keyPrefix: string;
  readonly name: string;
  readonly organisationId: string;
  readonly timezone: string;
  readonly type: WorkspaceType;
  readonly userIsOrgAdmin: boolean;
  readonly userWorkspacePermissions: {
    readonly admin: boolean;
  } | null;
  readonly uuid: string;
  readonly " $fragmentType": "storeHomeWorkspace";
};
export type storeHomeWorkspace$key = {
  readonly " $data"?: storeHomeWorkspace$data;
  readonly " $fragmentSpreads": FragmentRefs<"storeHomeWorkspace">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "storeHomeWorkspace"
};

(node as any).hash = "0c7fed645674cdc727f0953eead714b8";

export default node;

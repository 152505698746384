import { Environment, Network, RecordSource, Store } from 'relay-runtime';

import { getConfig } from '@townsquare/config';
import { PRODUCT_DISPLAY_NAME } from '@townsquare/config/constants';

import { fetchGraphQLFromUrl, logMissingRequiredField } from './RelayEnvironment';

const EXPERIMENTAL_JIRA_API_HEADER = { 'X-ExperimentalApi': 'JiraIssueSearch' };

const config = getConfig();
export const AggRelayEnvironment = new Environment({
  configName: `${PRODUCT_DISPLAY_NAME} AGG GraphQL`,
  network: Network.create(fetchGraphQLFromUrl({ defaultUrl: config.aggGraphQLUrl })),
  store: new Store(new RecordSource()),
  relayFieldLogger: logMissingRequiredField,
});

export const AggExperimentalJiraRelayEnvironment = new Environment({
  configName: `${PRODUCT_DISPLAY_NAME} AGG GraphQL Experimental`,
  network: Network.create(
    fetchGraphQLFromUrl({
      defaultUrl: config.aggGraphQLUrl,
      multiRegionUrl: undefined,
      customHeaders: EXPERIMENTAL_JIRA_API_HEADER,
    }),
  ),
  store: new Store(new RecordSource()),
  relayFieldLogger: logMissingRequiredField,
});

import { ContentTabName } from '@townsquare/content-tabs/types';
import { Milestone } from '@townsquare/milestones';
import { createRelayResource } from '@townsquare/relay-utils';
import type { ResourceContext } from '@townsquare/router';
import { getBooleanConfig } from '@townsquare/stat-sig/config';
import { isNavRefreshEnabled } from '@townsquare/stat-sig/nav4';

import ProjectUpdatesQuery, {
  type ProjectUpdatesQuery as ProjectUpdatesQueryType,
} from '../components/ProjectUpdatesTab/__generated__/ProjectUpdatesQuery.graphql';
import ProjectViewQuery, {
  type ProjectViewQuery as ProjectViewQueryType,
} from '../components/__generated__/ProjectViewQuery.graphql';

export const projectViewResource = createRelayResource<ProjectViewQueryType>({
  getQuery: (routerContext, resourceContext) => {
    const context = resourceContext as ResourceContext;
    const areMilestonesEnabled = getBooleanConfig('townsquare_enable_milestones_in_sidebar');

    if (areMilestonesEnabled) {
      Milestone.preload();
    }

    return {
      query: ProjectViewQuery,
      variables: {
        key: routerContext.match.params.id ?? '',
        trackViewEvent: 'DIRECT',
        workspaceId: context.workspace.globalId,
        onboardingKeyFilter: 'PROJECT_SPOTLIGHT',
        areMilestonesEnabled,
        cloudId: context.workspace.cloudId,
        isNavRefreshEnabled: isNavRefreshEnabled(context),
      },
    };
  },
});

export const projectUpdatesResource = createRelayResource<ProjectUpdatesQueryType>({
  getQuery: routerContext => {
    const tabName = routerContext.match.params.tabName;
    const isUpdatesTab = tabName === ContentTabName.UPDATES;

    return {
      query: ProjectUpdatesQuery,
      variables: {
        key: routerContext.match.params.id ?? '',
        trackViewEvent: 'DIRECT',
        isUpdatesTab,
      },
    };
  },
});
